import React, { ReactNode, useEffect, useRef } from 'react';
import { useScroll } from 'react-use';
import { getDocument } from 'pdfjs-dist';
import { ArrayUtil } from '@property-folders/common/util/array';
import { A4_ASPECT_RATIO_WH } from '@property-folders/common/util/pdfgen/measurements';
import { bind } from 'lodash';
import { CoordinateMath } from '@property-folders/common/util/coords';
import { mapMutateIfSet } from '@property-folders/common/util';
import clsJn from '@property-folders/common/util/classNameJoin';

export type ThumbnailWrapFunc = (props: React.PropsWithChildren<{
  pageIndex: number,
  pageCount: number
}>) => ReactNode;

const info = (...args: any[]) => {};// bind(console.log, console, 'PDFMinimap');
const error = bind(console.error, console, 'PDFMinimap');

interface PageInfo {
  ratioWh: number
}
type PageInfoMap = Map<number, PageInfo>;

export function PDFMinimap({ url, thumbnailWrapElement }: { url: string, thumbnailWrapElement?: ThumbnailWrapFunc }) {
  const ref = useRef<HTMLDivElement>(null);
  const [pages, setPages] = React.useState<PageInfoMap>(new Map());
  const [renders, setRenders] = React.useState<Map<number, string>>(new Map());
  const scroll = useScroll(ref);

  useEffect(() => {
    const ac = new AbortController();
    const signal = ac.signal;
    info('url changed, re-render and swap in pages', url);
    getDocument(url).promise
      .then(async doc => {
        if (signal.aborted) {
          info('abort signal');
          return;
        }
        info(doc.numPages, 'pages');
        setPages(cur => {
          if (cur.size === doc.numPages) return cur;
          return new Map<number, PageInfo>(ArrayUtil
            .range(doc.numPages, 1)
            .map((pageNum, index) => ([
              index,
              cur.get(index) || { ratioWh: A4_ASPECT_RATIO_WH }
            ])));
        });

        for (let i = 0; i < doc.numPages; i++) {
          const canvas = document.createElement('canvas');
          try {
            const ctx = canvas.getContext('2d');
            if (!ctx) continue;
            const pageNum = i + 1;
            const page = await doc.getPage(pageNum);
            if (signal.aborted) {
              info('aborted at page', pageNum);
              return;
            }

            const viewport = page.getViewport({ scale: 0.5 });
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const [_, __, width, height] = page.view;
            const angle = CoordinateMath.normaliseDegrees(page.rotate);
            const ratio = (angle === 90 || angle === 270)
              ? height / width
              : width / height;
            info('page', pageNum, width, height, angle, ratio);
            setPages(cur => mapMutateIfSet(
              cur,
              i,
              { ratioWh: ratio },
              (a, b) => a.ratioWh === b.ratioWh
            ));

            await page.render({
              canvasContext: ctx,
              viewport
            }).promise;

            const blob = await new Promise<Blob>((resolve, reject) => {
              canvas.toBlob(blob => {
                if (!blob) {
                  return reject(blob);
                }
                resolve(blob);
              });
            });

            if (signal.aborted) {
              info('aborted at page', pageNum);
              return;
            }

            const url = URL.createObjectURL(blob);
            setRenders(current => mapMutateIfSet(current, i, url));
          } finally {
            info('cleanup canvas for page', i);
            canvas.remove();
          }
        }
      })
      .catch(error);
    return () => {
      info('aborting...');
      ac.abort();
    };
  }, [url]);

  useEffect(() => {
    info('scroll', scroll.x, scroll.y);
  }, [scroll]);

  return <div
    ref={ref}
    className='px-2'
    style={{
      overflowY: 'scroll',
      background: 'var(--clr-bg-pdf-preview)',
      width: '100%',
      height: '100%',
      color: 'white'
    }}
  >
    {pages.size && ArrayUtil.range(pages.size, 1).map((pageNum, index) => {
      const pageInfo = pages.get(index);
      if (!pageInfo) return <React.Fragment key={index}></React.Fragment>;
      const renderUrl = renders.get(index);
      info(index, renderUrl);
      const child = <div
        style={{
          width: '100%',
          aspectRatio: pageInfo.ratioWh,
          backgroundImage: renderUrl
            ? `url(${renderUrl})`
            : undefined,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundColor: renderUrl
            ? undefined
            : 'white',
          cursor: 'pointer'
        }}
        onClick={e => {
          const pages = document.querySelectorAll(`.react-pdf__Page[data-page-number="${index + 1}"]`);
          for (const page of pages) {
            if (!(page instanceof HTMLElement)) continue;
            // we could also try checking if the page is visible if we really wanted to.
            if (!page.offsetParent) continue;
            page.scrollIntoView();
          }
        }}
      >
      </div>;

      return thumbnailWrapElement
        ? thumbnailWrapElement({
          children: child,
          pageIndex: index,
          pageCount: pages.size
        })
        : <div className={clsJn({ 'mt-2': index > 0 })}>
          {child}
        </div>;
    })}
  </div>;
}
