import { useTransactionField } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import '../Form.scss';
import { CollectionRemoveButton } from './CollectionRemoveButton';
import { MultiLineAddressSelector } from './MultilLineAddressSelector';
import { Suggestion } from './Suggestor';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { bindSetOrUnsetIfUndefined } from '@property-folders/common/util/immerTools';
import { composeStreetAddressFromParts, composeSubStatePostFromParts } from '@property-folders/common/util/formatting/string-composites';
import { Hundred } from './Hundred';
import { Lga } from './Lga';
import { WrField } from './CommonComponentWrappers';
import { IrrigationArea } from './IrrigationArea';
import { useState } from 'react';
import { useEntities } from '../../hooks/useEntity';
import { Agent, VendorParty, PartyType, SigningAuthorityType, MaterialisedPropertyData } from '@property-folders/contract';
import { values } from 'lodash';
import { canonicalisers, companyTradingAs } from '@property-folders/common/util/formatting';
import { EntitySettingsEntity } from '@property-folders/contract/yjs-schema/entity-settings';
import { v4 } from 'uuid';
import { PropertySearchApi } from '@property-folders/common/client-api/propertySearchApi';
import { usePropertyGnafLocation } from '../../hooks/usePropertyGnafLocation';
import { Alert } from 'react-bootstrap';
import { Icon } from '../Icon';
import { GenericConfirmDialog } from '../Wizard/GenericConfirmDialog';

type AddressInputProps = TransactionConsumerProps & {
  placeholder?: string,
  removable?: boolean,
  editable?: boolean,
  hideDelete?: boolean
  onUpdate?: (el: any) => void;
  onDelete?: (el: any) => void;
  duplicate?: boolean;
  autoFocus?: boolean;
  minimalUi?: boolean;
};

export const NarrowAddressInput = ({ placeholder, removable = true, editable = true, hideDelete = false, onUpdate, onDelete, duplicate, autoFocus, minimalUi = false, ...restProps }: AddressInputProps): JSX.Element => {
  const { fullPath, handleRemove, value: addressObj } = useTransactionField(restProps);
  const { updateDraft } = useYdocBinder({ path: fullPath });
  const { updateDraft: updateAgent } = useYdocBinder<Agent[]>({ path: 'agent' });
  const { updateDraft: updateVendors } = useYdocBinder<VendorParty[]>({ path: 'vendors' });
  const { updateDraft: updatePropertyFolder, rootBinder } = useYdocBinder<MaterialisedPropertyData>({ path: '' });
  const closeButton = handleRemove && <CollectionRemoveButton removable={removable} onRemove={ () => { if (onDelete) { onDelete(addressObj); } handleRemove(); } } />;
  const [doShowAdditionalDescription, setDoShowAdditionalDescription] = useState('additionalDesc' in addressObj && addressObj.additionalDesc);
  const [showUnlinkDialog, setShowUnlinkDialog] = useState(false);
  const entities = values(useEntities());
  const gnafCentre = usePropertyGnafLocation();
  const [errorMessage, setErrorMessage] = useState('');

  const updateAddressFromSuggestion = (suggestion: Suggestion) => {
    updateDraft?.(draft => {
      const setAddrField = bindSetOrUnsetIfUndefined(draft);
      const parts = suggestion.AddressParts;

      draft.streetAddr = composeStreetAddressFromParts(parts);
      draft.subStateAndPost = composeSubStatePostFromParts(parts);
      draft.streetAddr_parts = parts;
      draft.gnaf = suggestion.GNAF;
      delete draft.fromLssa;

      setAddrField('hundred', suggestion.Hundred || 'Out of Hundreds');
      setAddrField('irrigationArea', suggestion.IrrigationArea || '');
      setAddrField('lga', suggestion.LGA?.Name);
      setAddrField('suburb', parts.Suburb);

      if (suggestion.IntegrationListingId) {
        setAddrField('integrationId', suggestion.IntegrationListingId);
        setAddrField('integrationName', suggestion.IntegrationName);
        setAddrField('integrationUrl', suggestion.IntegrationListing?.webLink);
      }
    });

    onUpdate?.({ suggestion, address: addressObj });
  };

  const unlinkListing = () => {
    updateDraft?.(draft => {
      delete draft.integrationId;
      delete draft.integrationUrl;
      delete draft.integrationName;
    });
    setShowUnlinkDialog(false);
  };

  const updatePropertyFromIntegration = (suggestion: Suggestion) => {
    updateDraft?.(draft => {
      //add agency/salespeople from agentbox listing
      if (suggestion.IntegrationListing?.relatedStaffMembers?.length) {
        const agents = suggestion.IntegrationListing.relatedStaffMembers.filter(s => s.role === 'Listing Agent');
        let matchedEntity: EntitySettingsEntity | undefined;
        for (const agent of agents) {
          matchedEntity = entities.find(e => e?.salespeople?.find(s => s.email === agent.staffMember?.email)) as EntitySettingsEntity;
          if (matchedEntity) break;
        }

        if (matchedEntity) {
          updateAgent?.((draft:Agent[]) => {
            const agency = draft?.[0];
            const setEntityField = bindSetOrUnsetIfUndefined(agency);
            const compositeName = companyTradingAs(matchedEntity?.name, matchedEntity?.tradeName);
            agency.company = compositeName;
            agency.profileName = matchedEntity?.profileName || compositeName;
            setEntityField('abn', canonicalisers.abnacn(matchedEntity?.abn||'').canonical);
            setEntityField('rla', matchedEntity?.rla);
            setEntityField('linkedEntityId', matchedEntity?.entityId || undefined);

            agency.salesp = [];
            for (const agent of agents) {
              const salesperson = matchedEntity?.salespeople?.find(s => s.email === agent.staffMember?.email);
              agency.salesp.push({
                id: v4(),
                name: salesperson?.name || '',
                phone: canonicalisers.phone(salesperson?.phone || '').canonical,
                email: canonicalisers.email(salesperson?.email || '').canonical,
                linkedSalespersonId: salesperson?.id
              });
            }
          });
        }
      }

      let initialVendorId: string | undefined = undefined;
      if (suggestion.IntegrationListing?.relatedContacts?.length) {
        const vendors = suggestion.IntegrationListing?.relatedContacts?.filter(rc => ['Vendor', 'Past Vendor', 'Prospective Vendor'].includes(rc.role));
        const vendorContacts = vendors?.map(v => suggestion.IntegrationContacts?.find(ic => ic.id === v.contact?.id)) || [];
        const primaryVendorId = v4();
        updateVendors?.((draft:VendorParty[]) => {
          //remove the initial blank vendor if it exists
          if (!draft?.[0]?.partyType) {
            initialVendorId = draft[0].id;
            draft.splice(0);
          }

          //add vendors that dont already exist
          draft.push(...vendorContacts.filter(v => !draft.find(existing => existing.integrationId === v.id)).map((v,idx:number) => ({
            id: idx===0 ? primaryVendorId : v4(),
            partyType: PartyType.Individual,
            authority: SigningAuthorityType.self,
            integrationId: v.id,
            integrationUrl: v.links?.self,
            integrationName: suggestion.IntegrationName,
            fullLegalName: `${v.firstName} ${v.lastName}`,
            addressSingleLine: `${v.streetAddress?.address}, ${v.streetAddress?.suburb} ${v.streetAddress?.state} ${v.streetAddress?.postcode}`?.trim(),
            email1: v.email,
            phone1: v.mobile
          })));
        });

        //set the primary vendor if there isnt one
        const primaryVendor = rootBinder?.get()?.primaryVendor;
        (!primaryVendor || primaryVendor === initialVendorId) && updatePropertyFolder?.((draft:MaterialisedPropertyData) => {
          draft.primaryVendor = primaryVendorId;
        });
      }

      updateAddressFromSuggestion(suggestion);
    });
  };

  const onAddrSelect = (suggestion: Suggestion) => {
    setErrorMessage('');
    if (suggestion.IntegrationListingId) {
      try {
        PropertySearchApi.getIntegrationListing(suggestion.IntegrationListingId, gnafCentre).response.then(res => {
          updatePropertyFromIntegration(res);
        });
      } catch (e) {
        setErrorMessage(`Error retrieving integration: ${e}`);
      }
    } else {
      if (suggestion.AddressParts === undefined) {
        return;
      }
      updateAddressFromSuggestion(suggestion);
    }
  };

  const onSuburbSelect = (suggestion: Suggestion) => {
    updateDraft?.(draft => {
      const setAddrField = bindSetOrUnsetIfUndefined(draft);

      draft.subStateAndPost = `${suggestion.Name} ${suggestion.State} ${suggestion.Postcode}`;
      setAddrField('suburb', suggestion.Name);

      let lga = '';
      if (suggestion.LGA?.length === 1) {
        lga = suggestion.LGA[0];
      }

      let hundred = '';
      if (suggestion.Hundred?.length === 1) {
        hundred = suggestion.Hundred[0];
      }

      let irrigationArea = '';
      if (suggestion.IrrigationArea?.length === 1) {
        irrigationArea = suggestion.IrrigationArea[0];
      }

      setAddrField('lga', lga);
      setAddrField('hundred', hundred);
      setAddrField('irrigationArea', irrigationArea);
    });

    onUpdate?.(suggestion.FullAddress);
  };

  if (!editable) {
    return <div className='d-flex w-100 flex-column'>
      <div><span className='fs-5'>{addressObj?.streetAddr || 'Address'}</span></div>
      {addressObj?.subStateAndPost && <div><span className='text-muted'>{addressObj.subStateAndPost}</span></div>}
      {addressObj?.lga && <div><span className='text-muted'>{addressObj.lga}</span></div>}
      {addressObj?.hundred && <div><span className='text-muted'>Hundred of {addressObj.hundred}</span></div>}
      {addressObj?.additionalDesc && <div><span className='text-muted'>{addressObj?.additionalDesc}</span></div>}
    </div>;
  }

  return (
    <div className="d-flex w-100 gapped-row extra-gap narrow-address-input">
      <div className='w-100'>
        <div className="d-flex w-100 flex-wrap">
          <div style={{ flexGrow: 3, flexShrink: 0 }}>
            <MultiLineAddressSelector
              parentPath={fullPath}
              myPath={['streetAddr', 'subStateAndPost']}
              autoFocus={autoFocus}
              onSuburbSelect={onSuburbSelect}
              onAddrSelect={onAddrSelect}
              primaryConnected={!!addressObj?.streetAddr_parts}
              onPrimaryDisconnect={() => updateDraft?.(draft => {
                delete draft.streetAddr_parts;
              })}
              duplicate={duplicate}
              onChange={e => {
                updateDraft?.(draft => {
                  delete draft.fromLssa;
                });
                onUpdate?.({ address: addressObj });
              }}
              fromLssa={addressObj?.fromLssa}
            />
          </div>
          <div style={{ flexGrow: 2, flexShrink: 2, width: '110px', display: minimalUi ? 'none' : 'block' }}>
            <Hundred myPath="hundred" parentPath={fullPath} onChange={() => {
              onUpdate?.({ address: addressObj });
            }}/>
          </div>
          {addressObj?.irrigationArea && <div style={{ flexGrow: 2, flexShrink: 2, width: '110px', display: minimalUi ? 'none' : 'block' }}>
            <IrrigationArea myPath="irrigationArea" parentPath={fullPath} onChange={() => {
              onUpdate?.({ address: addressObj });
            }}/>
          </div>}
          <div style={{ flexGrow: 3, flexShrink: 1, display: minimalUi ? 'none' : 'block' }}>
            <Lga myPath="lga" parentPath={fullPath} onChange={() => {
              onUpdate?.({ address: addressObj });
            }}/>
          </div>
        </div>
        {addressObj.integrationId && <div className={'d-flex ms-1 mt-1'}>
          Integration: <div>
            <a className={'ms-1'} target={'_blank'} href={addressObj.integrationUrl}>{addressObj.integrationName}<Icon name={'open_in_new'} style={{ fontSize: '14px', marginLeft: '2px', marginBottom: '2px' }}/></a>
            <Icon name='link_off' icoClass={'cursor-pointer'} title={'Disconnect Integration'} style={{ fontSize: '18px', marginLeft: '10px' }} onClick={()=>setShowUnlinkDialog(true)} />
          </div>
        </div>}
        {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
        {doShowAdditionalDescription
          ? <div className={'d-flex flex-row'}>
            <div className={'flex-grow-1'}><WrField.Control textArea={{ rows: 2, minHeight: '5rem', height: '5rem' }} parentPath={fullPath} myPath='additionalDesc' name={fullPath+'.additionalDesc'} label='Additional description of Property' maxLength={2000}/></div>
            <div className={'align-content-end'}>
              <WrField.BoolCheck parentPath={fullPath} myPath='useAdditionalDescAsLegalDesc' name={fullPath+'.useAdditionalDescAsLegalDesc'} label='Use as legal description' className={'mt-2 ms-2'} />
            </div>
          </div>
          : <button type='button' className='btn btn-link ms-1 ps-0' onClick={() => setDoShowAdditionalDescription(true)}>Add additional description of property</button>
        }
      </div>
      <div className="d-flex flex-wrap justify-content-center" style={{ maxWidth: '32px' }}>
        {(!hideDelete) && <div className='delete-div'>
          {closeButton}
        </div>}
      </div>
      {showUnlinkDialog && <GenericConfirmDialog
        onContinue={unlinkListing}
        onCancel={()=>setShowUnlinkDialog(false)}
        title={`Disconnect the ${addressObj.integrationName} Listing`}
        message='Greatforms will no longer be able to suggest Contacts related to this Listing'
        okLabel='Disconnect'
      />}
    </div>
  );
};
