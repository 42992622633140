import React, { useCallback, useContext, useState } from 'react';
import { LineageContext } from '../../hooks/useVariation';
import { WizardDisplayContext } from '../../context/WizardContexts';
import { SpinnerButton } from '../AsyncButton';
import { Button } from 'react-bootstrap';
import { SplitIfManyButton } from '../SplitIfManyButton';
import { Predicate } from '@property-folders/common/predicate';

type ButtonActionCallback =
  | (() => Promise<void>)
  | (() => void);
type ButtonAction = {
  fn: ButtonActionCallback,
  label: string,
  waitingLabel: string
};

export function SigningConfigurationNextButton({
  actions
}: {
  actions: (ButtonAction | undefined)[]
}) {
  const [waiting, setWaiting] = useState('');
  const { snapshotData } = useContext(LineageContext);
  const { focusErrList } = useContext(WizardDisplayContext);
  const onClick = useCallback((action: ButtonAction) => {
    setWaiting(action.waitingLabel);
    return Promise.resolve(action.fn())
      .catch(console.error)
      .finally(() => setWaiting(''));
  }, [snapshotData]);

  return <div className="d-flex flex-column gap-1 align-items-end">
    {waiting
      ? <SpinnerButton processing={true}>{waiting}</SpinnerButton>
      : <SplitIfManyButton>
        {actions.filter(Predicate.isNotNull).map(action => (
          <Button
            key={action.label}
            disabled={Boolean(focusErrList.length)}
            onClick={() => onClick(action)}>{action.label}</Button>
        ))}
      </SplitIfManyButton>}
  </div>;
}
