import { FormUtil } from '@property-folders/common/util/form';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { FormInstance, LookupUsersResultItem, SigningSessionCCItem } from '@property-folders/contract';
import { FormContext } from '../../context/FormContext';
import { YjsDocContext } from '../../context/YjsDocContext';
import { useLightweightTransaction } from '../../hooks/useTransactionField';
import { useContext, useEffect, useMemo, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { v4 } from 'uuid';
import { Lookups } from '@property-folders/common/client-api/lookups';

interface CCOption {
  // selection data
  value: string;
  label: string;
  // metadata
  srcId?: string;
  name?: string;
  type: 'email' | 'phone';
}

export function CCRecipientSelector() {
  const { ydoc } = useContext(YjsDocContext);
  const { formId, formName: formCode } = useContext(FormContext);
  const { value: form, fullPath: formPath } = useLightweightTransaction<FormInstance>({ myPath: FormUtil.getFormPath(formCode, formId), bindToMetaKey: true });
  const { updateDraft: updateForm } = useYdocBinder<FormInstance>({ path: formPath, bindToMetaKey: true });
  const [users, setUsers] = useState<LookupUsersResultItem[]>([]);

  useEffect(() => {
    const { ac, results } = Lookups.lookupUsers();

    results.then(data => {
      if (ac.signal.aborted) return;
      if (!data?.items?.length) return;
      setUsers(data?.items);
    }).finally();

    return () => {
      ac.abort();
    };
  }, []);

  const { options, value } = useMemo(() => {
    const ccs = form?.cc || [];
    const srcIds = new Set(ccs.map(({ id }) => id) || []);
    const options: CCOption[] = [];
    const seen = new Set<string>();

    // you shouldn't be able to cc signing parties
    for (const party of form?.signing?.parties || []) {
      if (party?.snapshot?.email) {
        seen.add(party.snapshot.email);
      }
      if (party?.snapshot?.phone) {
        const canon = canonicalisers.phone(party.snapshot.phone);
        if (canon.valid) {seen.add(canon.canonical.toString());}
      }
    }

    // add pre-existing cc entries
    for (const cc of ccs) {
      switch (cc.type) {
        case 'email':
          options.push({ srcId: cc.id, value: cc.email, label: formatLabel(cc.email, cc.name), name: cc.name, type: cc.type });
          seen.add(cc.email);
          break;
        case 'phone':
          options.push({ srcId: cc.id, value: cc.phone, label: formatLabel(canonicalisers.phone(cc.phone).display, cc.name), name: cc.name, type: cc.type });
          seen.add(cc.phone);
          break;
      }
    }

    const onContact = (contact: CCOption) => {
      if (seen.has(contact.value)) return;
      seen.add(contact.value);
      options.push(contact);
    };

    for (const user of users) {
      if (user.email) {
        onContact({ type: 'email', value: user.email, label: formatLabel(user.email, user.name), name: user.name });
      } else if (user.phone) {
        onContact({ type: 'phone', value: user.phone, label: formatLabel(canonicalisers.phone(user.phone).display, user.name), name: user.name });
      }
    }

    options.sort((a, b) => a.label.localeCompare(b.label));

    return {
      options,
      value: options.filter(o => o.srcId && srcIds.has(o.srcId))
    };
    // look into any ydoc triggers that can be used to recalculate the contacts list
  }, [form, ydoc, users]);

  const selectCCItem = (item: SigningSessionCCItem) => {
    updateForm?.(draft => {
      if (!draft.signing?.session) return;
      if (draft.cc) {
        draft.cc.push(item);
      } else {
        draft.cc = [];
        draft.cc.push(item);
      }
    });
  };

  const removeCCItem = (id: string | undefined) => {
    if (!id) return;
    updateForm?.(draft => {
      if (!draft?.cc?.length) return;
      const cc = draft.cc;
      const idx = cc.findIndex(x => x.id === id);
      if (idx >= 0) {
        cc.splice(idx, 1);
      }
    });
  };

  return <CreatableSelect
    isMulti
    isSearchable
    isClearable={false}
    options={options}
    value={value}
    menuPlacement='auto'
    placeholder={'Add a contact, mobile number, or email'}
    onChange={(options, action) => {
      switch (action.action) {
        case 'remove-value':
          removeCCItem(action.removedValue.srcId);
          return;
        case 'select-option':
          if (!action.option) return;
          switch (action.option.type) {
            case 'email':
              selectCCItem({ id: v4(), type: action.option.type, email: action.option.value, name: action.option.name });
              break;
            case 'phone':
              selectCCItem({ id: v4(), type: action.option.type, phone: action.option.value, name: action.option.name });
              break;
          }
          return;
      }
    }}
    onCreateOption={value => {
      const email = canonicalisers.email(value);
      const phone = canonicalisers.phone(value);
      if (!email.valid && !phone.valid) return;

      const item: SigningSessionCCItem = email.valid
        ? { id: v4(), type: 'email', email: email.canonical.toString() }
        : { id: v4(), type: 'phone', phone: phone.canonical.toString() };
      selectCCItem(item);
    }}
    formatCreateLabel={value => value}
    formatOptionLabel={(data, meta) => {
      if ('__isNew__' in data && data.__isNew__) {
        if (canonicalisers.email(meta.inputValue).valid) {
          return `Add email: ${meta.inputValue}`;
        }
        // assumption is this doesn't show unless isValidNewOption passes
        return `Add phone: ${meta.inputValue}`;
      }

      return data.label;
    }}
    isValidNewOption={value => Boolean(value && (canonicalisers.email(value).valid || canonicalisers.phone(value).valid))}
    styles={{
      control: (base, state) => ({
        ...base,
        ...state.isFocused ? {
          borderColor: '#8db7fb',
          outline: '0',
          boxShadow: '0 0 0 0.25rem rgba(26, 110, 246, 0.25)',
          ':hover': {
            borderColor: '#8db7fb'
          }
        } : undefined,
        background: 'var(--bs-body-bg)',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'var(--clr-input-border)',
        borderRadius: 0,
        fontSize: '14px',
        lineHeight: '1.25'
      }),
      dropdownIndicator: (base) => ({
        ...base,
        color: '#343a40',
        cursor: 'pointer',
        ':hover': {
          color: '#343a40'
        }
      }),
      indicatorSeparator: () => ({
        display: 'none'
      }),
      menu: base => ({ ...base, borderRadius: '0', zIndex: 11 }),
      option: (base) => ({
        ...base,
        backgroundColor: 'white',
        color: 'black',
        ':hover': { backgroundColor: '#e9ecef', color: 'black' }
      })
    }}
  />;
}

function formatLabel(value: string, name?: string) {
  return name
    ? `${name} (${value})`
    : value;
}
