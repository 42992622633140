import { coolingOffOpts, depositPayAtOpts } from '@property-folders/contract';
import { Button, Modal } from 'react-bootstrap';
import { useTransactionField } from '../../../hooks/useTransactionField';

export type CoolingOffModalData = {
  visible: boolean,
  changedField: 'coolingOff' | 'deposit',
  newValue: string
};

export const CoolingOffModal = ({ visible, changedField, newValue, onClose } : CoolingOffModalData & {onClose: (accepted: boolean) => void}): JSX.Element => {
  const { handleUpdate: updateDepositPayment } = useTransactionField<string>({ myPath: 'contractPrice.depositPayAt' });
  const { handleUpdate: updateCoolingOff } = useTransactionField<string>({ myPath: 'coolingOff.purchaserRight' });

  return (
    <Modal show={visible} size='lg' onHide={() => onClose(false)}>
      <Modal.Header closeButton>
        <div className='d-flex flex-column'>
          <Modal.Title>Update {changedField === 'coolingOff' ? 'deposit payment' : 'cooling-off'}?</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
          Do you want to update the {changedField === 'coolingOff' ? 'deposit payment' : 'cooling-off'} selection in this Contract to be:
        <div className={'my-3 ms-4 fw-bold'}>
          {changedField === 'coolingOff' ? depositPayAtOpts?.[newValue] : coolingOffOpts?.[newValue]}
        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button variant='outline-secondary' onClick={() => onClose(false)}>Ignore</Button>
        <Button variant='primary' onClick={() => {
          if (changedField === 'coolingOff') {
            updateDepositPayment(newValue, true);
          } else {
            updateCoolingOff(newValue, true);
          }
          onClose(true);
        }}>Update</Button>
      </Modal.Footer>
    </Modal>
  );
};
