import { useState } from 'react';
import { VendorCollection } from './VendorCollection';
import { VendorImportModal } from './VendorImportModal';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '../../display/errors/modals';
import { PartyAuthorityInputProps } from './PartyAuthorityInput';

export function VendorCollectionWithImport(props: {
  autoAddFirst: any,
  entityId?: number;
  show: boolean,
  childProps?: Partial<PartyAuthorityInputProps>
  onHide: () => void,
  setHeaderActions?: () => void
}) {
  const [vendorIsSearching, setVendorIsSearching] = useState(false);

  return <>
    <VendorCollection
      autoAddFirst={props.autoAddFirst}
      useTitle={false}
      childProps={props.childProps}
      collectionMaskingText={vendorIsSearching ? 'Getting Vendor details from LSSA' : undefined}
      setHeaderActions={props.setHeaderActions}
    />
    {props.entityId &&
    <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={props.show} onClose={props.onHide} />}>
      <VendorImportModal
        show={props.show}
        onHide={props.onHide}
        entityId={props.entityId}
        setIsSearching={setVendorIsSearching}
      />
    </ErrorBoundary>}
  </>;
}
