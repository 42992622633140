import { SigningApi } from '@property-folders/common/client-api/signing';
import { mergePaths, normalisePathToStr } from '@property-folders/common/util/pathHandling';
import { FormCode, FormCodeUnion, FormInstance, FormSigningState, MaterialisedPropertyData, PartyDistributionState, SignedStates, SigningParty, TransactionMetaData } from '@property-folders/contract';
import { PathType } from '@property-folders/contract/yjs-schema/model';
import { WrField } from '../../dragged-components/form/CommonComponentWrappers';
import { Alert, Button, FloatingLabel, Form, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap';
import { Predicate } from '@property-folders/common/predicate';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { useContext, useState } from 'react';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { YjsDocContext } from '../../context/YjsDocContext';
import { applyMigrationsV2_1 } from '@property-folders/common/yjs-schema';
import { FormTypes, PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import { ShowGuidanceNotesButton } from '../../dragged-components/guidance/ShowGuidanceNotesButton';
import { getProxyWithOriginalName } from '@property-folders/common/util/dataExtract';
import { FileStorage } from '@property-folders/common/offline/fileStorage';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { usePdfWorker } from '../../hooks/usePdfWorker';
import { downloadObjectUrl } from '../../react-util/download-object-url';
import { Y } from '@syncedstore/core';
import { CCRecipientSelector } from './CCRecipientSelector';
import { CCRecipientsState } from './CCRecipientsState';
import { DistributionPartyRow, SendingNowParty } from './DistributionPartyStatusPanel';

function someContactAvailable (party?: SigningParty) {
  const proxyMode = Predicate.proxyNotSelf(party?.proxyAuthority);
  return !!(proxyMode ? (party?.proxyEmail || party?.proxyPhone) : (party?.snapshot?.phone || party?.snapshot?.email));
}

function markPartyDistributed(
  ydoc: Y.Doc,
  metaRootKey: string,
  formCode: FormCodeUnion,
  formId: string,
  partyId: string,
  stateTransition: ({
  date: string,
  newState: 'manual'
  }|{newState: 'manualWaiting'})

) {
  const { newState } = stateTransition;
  const applyDistributionStateChangeTo = (party: { distributionState?: PartyDistributionState }) => {
    if (!party.distributionState) {
      party.distributionState = { type: newState, declaredDate: stateTransition.date };
      return;
    }
    if (newState === 'manualWaiting' && party.distributionState.type === 'delayed') {
      party.distributionState.type = newState;
      return;
    }
    if (newState === 'manual' && party.distributionState.type === 'manualWaiting') {
      party.distributionState.type = newState;
      party.distributionState.declaredDate = stateTransition.date;
      return;
    }
  };

  applyMigrationsV2_1({
    doc: ydoc,
    docKey: metaRootKey,
    typeName: 'Property',
    migrations: [{
      name: 'Mark party as manually distributed',
      fn: (draft: TransactionMetaData)=>{
        const instance = PropertyFormYjsDal.getFormInstanceFromState(formCode, formId, draft);
        const party = instance?.signing?.parties?.find(p=>p.id === partyId);
        if (party) {
          return applyDistributionStateChangeTo(party);
        }

        const cc = instance?.cc?.find(cc => cc.id === partyId);
        if (cc) {
          return applyDistributionStateChangeTo(cc);
        }
      }
    }]
  });
}

interface ModalTargetParty {
  id: string;
  type: 'party' | 'cc'
  name: string;
}

export function SigningSessionDistribution(props: {
  instance: FormInstance
  instancePath: PathType
  parties: SigningParty[]
  propertyData?: MaterialisedPropertyData
}) {
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { ydoc, transactionMetaRootKey, docName } = useContext(YjsDocContext);
  const [markingParty, setMarkingParty] = useState<null|ModalTargetParty>(null);
  const [downloadingParty, _setDownloadingParty] = useState<null|ModalTargetParty>(null);
  const [downloadError, setDownloadError] = useState<string>('');
  const setDownloadingParty = (party: null|ModalTargetParty) => {
    if (!party) setDownloadError('');
    _setDownloadingParty(party);
  };

  const [markDate, setMarkDate] = useState<string>('');
  const [resendingParty, setResendingParty] = useState<null|ModalTargetParty>(null);
  const [sendingParty, setSendingParty] = useState<null|ModalTargetParty>(null);
  const [showSendNow, setShowSendNow] = useState<boolean>(false);
  // Faking the sending status a bit. If the user refreshes the page, the delayed status will return
  const [sendingNow, setSendingNow] = useState<boolean|SendingNowParty>(false);

  const [sendError, setSendError] = useState<boolean>(false);
  const { instance, instancePath, parties } = props;

  function handleMarkPartyManuallyDistributed(partyId: string|undefined, date: string|undefined) {
    if (!(partyId && date && canonicalisers.date(date??'').valid)) return;
    markPartyDistributed(
      ydoc,
      transactionMetaRootKey,
      instance.formCode,
      instance.id,
      partyId,
      {
        date,
        newState: 'manual'
      }
    );
  }
  function promptToMark(party: ModalTargetParty) {
    setMarkingParty(party);
  }
  function handleResend(partyId: string|undefined) {
    const signingSessionId = instance.signing?.session?.id;
    if (!(docName && signingSessionId && partyId)) return;
    SigningApi.resendCompletedLink(docName, signingSessionId, partyId);
  }

  const downloadAvailable = !Array.isArray(instance.signing?.session?.completedFile) || instance.signing?.session?.completedFile.length > 0;

  const pdfWorker = usePdfWorker();
  async function composeCompletedFilesAndDownload() {
    if (!instance.signing?.session?.completedFile) return false;
    const completedFiles = Array.isArray(instance.signing?.session?.completedFile) ? instance.signing?.session?.completedFile : [instance.signing?.session?.completedFile];
    let pdfData;
    if (completedFiles.length > 1) {
      const allPdfBytes = (await Promise.all(completedFiles.map(async fileRef => {
        const file = await FileStorage.read(fileRef.id);

        if (!file?.data) return null;
        return await file.data.arrayBuffer();
      }))).filter(Predicate.isNotNullish);

      pdfData = new Blob([await pdfWorker.stitchPdf({ pdfs: allPdfBytes })]);
    } else {
      const file = await FileStorage.read(completedFiles[0].id);
      if (!file?.data) return false;
      pdfData = file.data;
    }
    if (!pdfData) return false;
    const url = URL.createObjectURL(pdfData);
    const headline = generateHeadlineFromMaterialisedData(props.propertyData);
    downloadObjectUrl(url, `Completed ${FormTypes[instance.formCode].label}${headline ? ' for '+headline : ''}.pdf`);
    return true;
  }

  return <>
    {!SignedStates.has(instance?.signing?.state) && <div className='d-flex flex-row'><WrField.BoolCheck
      name='delayFullAutoDistribute'
      label={`Automatically distribute the fully signed ${instance?.formCode === FormCode.UploadedDocument ? 'document' : 'agreement'} to the parties once completed`}
      parentPath={normalisePathToStr(mergePaths(instancePath, 'signing'))}
      myPath='delayFullAutoDistribute'
      bindToMetaKey={true}
      inverter={true}
      nullishIsFalse={true}
    />
    <span style={{ marginLeft: '-0.5rem' }}><ShowGuidanceNotesButton noteId={'distributionDelay'} /></span>
    </div>}

    {SignedStates.has(instance?.signing?.state) && instance?.signing?.state !== FormSigningState.Signed && <div>
      Signing is currently executing
    </div>}

    {instance.signing?.session?.delayAcknowledged && !instance.signing.session?.delayFulfilled
    && (typeof sendingNow !== 'boolean' || !sendingNow) && instance?.signing?.state === FormSigningState.Signed
    && instance.signing?.parties?.some(party=>party.distributionState?.type === 'delayed' && someContactAvailable(party))
    && (<Alert variant={sendError ? 'warning' : 'info'}>
      {!sendError && <>
        <p>Distribution of this document to the parties has been delayed.</p>
        <p>If not distributed beforehand, the document will be automatically shared with the parties at 11pm (Adelaide time) today.</p>
      </>}
      {sendError && <>
        <p>There was an error in sending the distribution now. Please try again. If it still does not work, please contact support@reaforms.com.au</p>

      </>}
      <div className='d-flex w-100'>
        <Button className='ms-auto' onClick={()=>setShowSendNow(true)}>Send Now</Button>
      </div>
    </Alert>)}

    {instance?.signing?.state === FormSigningState.Signed && parties.map(p=>{
      const asModalTarget: ModalTargetParty = {
        id: p.id,
        type: 'party',
        name: getProxyWithOriginalName(p) || ''
      };
      return <DistributionPartyRow
        party={p}
        sessionInfo={sessionInfo}
        key={p.id}
        sendingNow={sendingNow}
        downloadAvailable={downloadAvailable}
        onMarkPartyManuallyDistributed={() => promptToMark(asModalTarget)}
        onPartyToDownload={() => setDownloadingParty(asModalTarget)}
        onResendToParty={() => setResendingParty(asModalTarget)}
        onSendToParty={() => setSendingParty(asModalTarget)}
        onRedownload={composeCompletedFilesAndDownload}
      />;
    })}

    {SignedStates.has(instance?.signing?.state)
      ? instance?.signing?.state === FormSigningState.Signed
        ? <>
          <CCRecipientsState
            onMarkPartyManuallyDistributed={p => {
              setResendingParty({
                id: p.id,
                type: 'cc',
                name: p.name || ('phone' in p ? p.phone : p.email)
              });
            }}
            onPartyToDownload={p => {
              setResendingParty({
                id: p.id,
                type: 'cc',
                name: p.name || ('phone' in p ? p.phone : p.email)
              });
            }}
            onResendToParty={p => {
              setResendingParty({
                id: p.id,
                type: 'cc',
                name: p.name || ('phone' in p ? p.phone : p.email)
              });
            }}
            onSendToParty={p => {
              setSendingParty({
                id: p.id,
                type: 'cc',
                name: p.name || ('phone' in p ? p.phone : p.email)
              });
            }}
            onRedownload={p => {
              composeCompletedFilesAndDownload();
            }}
          />
        </>
        : <></>
      : <>
        <p className='mt-3'>You may select additional recipients to receive the document when it is fully signed:</p>
        <CCRecipientSelector />
      </>}

    <Modal show={!!markingParty} onHide={()=>setMarkingParty(null)}>
      <ModalHeader closeButton><ModalTitle>Confirm {markingParty?.name || ''} as distributed</ModalTitle></ModalHeader>
      <ModalBody>
        <p>
            Please enter the date the fully signed document was distributed to {markingParty?.name}.
        </p>
        <div className='d-flex'>
          <FloatingLabel label="Date of distribution" style={{ width: '220px' }}>
            <Form.Control type='date' onChange={event=>setMarkDate(event.target.value)}/>
          </FloatingLabel>
        </div>
      </ModalBody>
      <ModalFooter  className='d-flex'>
        <Button variant='outline-secondary' onClick={()=>setMarkingParty(null)}>Cancel</Button>
        <Button
          variant='primary'
          disabled={!canonicalisers.date(markDate).valid}
          onClick={()=>{
            handleMarkPartyManuallyDistributed(markingParty?.id, markDate);
            setMarkingParty(null);
          }}>Confirm</Button>
      </ModalFooter>
    </Modal>

    <Modal show={!!downloadingParty} onHide={()=>setDownloadingParty(null)}>
      <ModalHeader closeButton><ModalTitle>Download for distribution</ModalTitle></ModalHeader>
      <ModalBody>
        <p>
            You are downloading the completed agreement to manually give to {downloadingParty?.name}. After you
            have given the completed agreement to {downloadingParty?.name}, you can Confirm their receipt of the
            agreement.
        </p>
      </ModalBody>
      <ModalFooter  className='d-flex'>
        <Button variant='outline-secondary' onClick={()=>setDownloadingParty(null)}>Cancel</Button>
        <Button
          variant='primary'
          onClick={()=>{
            if (!downloadingParty) return;
            composeCompletedFilesAndDownload().then(success=>{
              if (!success) {
                setDownloadError('Error preparing file for download');
                return;
              }
              markPartyDistributed(
                ydoc,
                transactionMetaRootKey,
                instance.formCode,
                instance.id,
                downloadingParty.id,
                { newState: 'manualWaiting' }
              );
              setDownloadError('');
              setDownloadingParty(null);
            });

          }}>Download</Button>
      </ModalFooter>
    </Modal>

    <Modal show={!!resendingParty} onHide={()=>setResendingParty(null)}>
      <ModalHeader closeButton><ModalTitle>Resend completed document</ModalTitle></ModalHeader>
      <ModalBody>
        <p>
            Do you wish to send the {FormTypes[instance.formCode].label} to {resendingParty?.name} again?
        </p>
      </ModalBody>
      <ModalFooter  className='d-flex'>
        <Button variant='outline-secondary' onClick={()=>setResendingParty(null)}>Cancel</Button>
        <Button variant='primary' onClick={()=>{handleResend(resendingParty?.id);setResendingParty(null);}}>Resend</Button>
      </ModalFooter>
    </Modal>

    <Modal show={showSendNow} onHide={()=>setShowSendNow(false)}>
      <ModalHeader closeButton><ModalTitle>Send all delayed documents</ModalTitle></ModalHeader>
      <ModalBody>
        <p>
        Do you wish to send the {FormTypes[instance.formCode].label} to the parties now?
        </p>
      </ModalBody>
      <ModalFooter  className='d-flex'>
        <Button variant='outline-secondary' onClick={()=>setShowSendNow(false)}>Cancel</Button>
        <Button variant='primary' onClick={()=>{
          setSendError(false);
          SigningApi.sendDelayedDistributions(props.propertyData?.id, instance.signing?.session?.id).catch(()=>{
            setSendingNow(false);
            setSendError(true);
          });
          setSendingNow(true);
          setShowSendNow(false);
        }}>Send</Button>
      </ModalFooter>
    </Modal>

    <Modal show={!!sendingParty} onHide={()=>setSendingParty(null)}>
      <ModalHeader closeButton><ModalTitle>Send delayed document to party</ModalTitle></ModalHeader>
      <ModalBody>
        <p>
        Do you wish to send the {FormTypes[instance.formCode].label} to {sendingParty?.name || ''} now?
        </p>
      </ModalBody>
      <ModalFooter  className='d-flex'>
        <Button variant='outline-secondary' onClick={()=>setSendingParty(null)}>Cancel</Button>
        <Button variant='primary' onClick={()=>{
          setSendError(false);
          SigningApi.sendDelayedDistributionToParty(props.propertyData?.id, instance.signing?.session?.id, sendingParty?.id).catch(()=>{
            setSendingNow(false);
            setSendError(true);
          });
          setSendingNow(sendingParty??false);
          setSendingParty(null);
        }}>Send</Button>
      </ModalFooter>
    </Modal>
  </>;
}
