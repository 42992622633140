import { $getRoot, $getSelection, RootNode } from 'lexical';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { NodeEventPlugin } from '@lexical/react/LexicalNodeEventPlugin';
import { ToolbarPlugin } from './lexical/ToolbarPlugin';
import { DisabledPlugin } from './lexical/DisabledPlugin';
import './RichTextEditor.scss';
import clsJn from '@property-folders/common/util/classNameJoin';
import React, { memo, useMemo } from 'react';
import { ReplacementTokenNode, ReplacementTokenPlugin } from './lexical/replacement-token-plugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { MaxLengthPlugin } from './lexical/MaxLengthPlugin';
import { Placeholder } from '@property-folders/common/workflow-rules/WorkflowTriggerTemplates';

type PlainTextEditorProps = {
  namespace: string
  onUpdate?: (output: string) => void;
  className?: string;
  value?: string;
  disabled?: boolean;
  onFocus?: (e: Event) => void;
  onBlur?: (e: Event) => void;
  style?: React.CSSProperties
  contentEditableStyle?: React.CSSProperties;
  toolbar?: () => JSX.Element;
  tokens?: Placeholder[];
  onCharacterCountChange?: (count:number) => void;
};

export const PlainTextEditorComponent = (props: PlainTextEditorProps) => {
  const editable = !props.disabled ?? true;
  const tokenNames = useMemo(()=> new Set(props.tokens?.map(t => t.value)), [props.tokens]);

  const onFocus = (e: Event) => {
    if (typeof props.onFocus === 'function') {
      props.onFocus(e);
    }
  };
  const onBlur = (e: Event) => {
    if (typeof props.onBlur === 'function') {
      props.onBlur(e);
    }
  };

  const initialConfig: InitialConfigType = {
    namespace: props.namespace,
    onError: error => {
      console.error(error);
    },
    nodes: [
      ReplacementTokenNode
    ], // not all are allowed, but MD plugin requires them.
    editorState: editor => {
      if (!props.value) {
        return;
      }
      editor.update(() => {
        $getRoot().select();
        $getSelection()?.insertText(props.value||'');
      });
    },
    editable
  };

  return <div className={clsJn('rich-text-editor', props.className)} style={props.style}>
    <LexicalComposer initialConfig={initialConfig}>
      <NodeEventPlugin
        nodeType={RootNode}
        eventType='focus'
        eventListener={onFocus}
      />

      <NodeEventPlugin
        nodeType={RootNode}
        eventType='blur'
        eventListener={onBlur}
      />

      {editable
        ? props.toolbar
          ? props.toolbar()
          : <ToolbarPlugin />
        : <></>}

      <PlainTextPlugin
        ErrorBoundary={LexicalErrorBoundary}
        contentEditable={<ContentEditable
          className='rte-content-editable form-control'
          key={props.namespace}
          style={props.contentEditableStyle}
        />}
        placeholder={<div className="rte-placeholder">Enter some text...</div>}
      />

      <OnChangePlugin
        onChange={(editorState, editor, _tags) => {
          editorState.read(() => {
            if (typeof props.onUpdate === 'function') {
              props.onUpdate(editor.getRootElement()?.textContent||'');
            }
          });
        }}
      />

      <MaxLengthPlugin maxLength={280} tokens={props.tokens} onCharacterCountChange={props.onCharacterCountChange} />

      <DisabledPlugin disabled={props.disabled} />
      <ReplacementTokenPlugin tokens={tokenNames} />
    </LexicalComposer>
  </div>;
};

export const PlainTextEditor = memo(PlainTextEditorComponent);
