import { CreateCustomFieldDetails, CustomFieldDetails, DraggableType, ExistingCustomFieldDetails } from './types';
import React, { CSSProperties, useEffect, useState } from 'react';
import { Icon } from '../../Icon';
import { NumberSize, Resizable } from 're-resizable';
import { Direction } from 're-resizable/lib/resizer';
import clsJn from '@property-folders/common/util/classNameJoin';
import { customFieldMetas } from '@property-folders/contract/property/meta';
import {
  CustomFieldType,
  MaterialisedPropertyData,
  SigningPartySnapshot,
  SigningPartyType
} from '@property-folders/contract';
import { PartyCategory } from '@property-folders/common/yjs-schema/property/form';
import { FormattedFieldText, getFieldText } from './FormattedFieldText';
import { RemoteCheckbox, RemoteRadio } from './RemoteCheckbox';
import { formatNumericAttribute, getStyles } from './common';

export function CustomField({ details, parties, dragging, contentScale, property }: { details: CustomFieldDetails, parties: CustomFieldPartyInfo[], dragging: boolean, contentScale: number, property: MaterialisedPropertyData }) {
  switch (details.mode) {
    case DraggableType.Create:
      return <CreateCustomField details={details} parties={parties} dragging={dragging} />;
    case DraggableType.Existing:
      return <ExistingCustomField details={details} parties={parties} dragging={dragging} resizable={false} onResize={() => {}} contentScale={contentScale} property={property} />;
  }
}

export function CreateCustomField({
  details,
  parties,
  dragging,
  fillWidth,
  disableReason
}: {
  details: CreateCustomFieldDetails,
  parties: CustomFieldPartyInfo[],
  dragging: boolean,
  fillWidth?: boolean,
  disableReason?: string
}) {
  const party = parties.find(p => p.id === details.partyId);

  return <div
    style={{
      width: fillWidth ? '100%' : `${details.width || 100}px`,
      height: `${details.height || 25}px`,
      ...generatePartyCustomFieldStyle(party?.colour),
      background: disableReason ? 'lightgrey' : 'white',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: '0.5em'
    }}
    className={clsJn(
      defaultFieldClassName,
      dragging && 'shadow'
    )}
    title={disableReason}
  >
    <Icon {...customFieldMetas[details.type].icon} />
    <div className='overflow-hidden' style={{ maxHeight: '100%' }}>{getFieldText(details, undefined, undefined)}</div>
  </div>;
}

export interface CustomFieldPartyInfo {
  id: string;
  type: SigningPartyType;
  colour: string;
  category?: PartyCategory;
  snapshot?: SigningPartySnapshot;
  isFirstOrdered?: boolean;
}

interface ResizeSize {
  top: number,
  left: number,
  width: number,
  height: number
}

const remoteCompletionType = [CustomFieldType.remoteText, CustomFieldType.remoteCheck, CustomFieldType.remoteRadio];

export function ExistingCustomField({
  details,
  parties,
  dragging,
  resizable,
  onResizing,
  onResize,
  selected,
  otherSelected,
  onSelect,
  onContext,
  contentScale,
  property,
  onEdit,
  onAttrChange
}: {
  details: ExistingCustomFieldDetails,
  parties: CustomFieldPartyInfo[],
  dragging: boolean,
  resizable: boolean,
  onResizing: (delta: ResizeSize) => boolean,
  onResize: (delta: ResizeSize) => boolean,
  selected?: boolean,
  otherSelected?: boolean,
  onSelect?: () => void, onContext?: (x: number, y: number, target: Element) => void,
  contentScale: number,
  property: MaterialisedPropertyData,
  onEdit?: (text: string) => void,
  onAttrChange?: (name: string, raw: any) => void
}) {
  // note: x/y positioning is managed externally because it's relative to a page.
  const [size, setSize] = useState<ResizeSize>({
    top: 0,
    left: 0,
    width: details.position.width,
    height: details.position.height
  });
  const [dragStartSize, setDragStartSize] = useState<undefined | ResizeSize>();
  const [allowed, setAllowed] = useState(true);

  // after calling onResize, some state might update and get passed back in.
  // good time to reset this component's state ready for more resizing
  useEffect(() => {
    setSize({
      top: 0,
      left: 0,
      width: details.position.width,
      height: details.position.height
    });
  }, [details.position.width, details.position.height]);

  const [hover, setHover] = useState(false);
  useEffect(() => {
    if (!selected) {
      setHover(false);
    }
  }, [selected]);

  const party = 'partyId' in details
    ? parties.find(p => p.id === details.partyId)
    // things like sale address/title do not have a party attached
    : remoteCompletionType.includes(details.type)
      ? parties.find(p => p.isFirstOrdered)
      : undefined;
  const color = 'fontColour' in details && details.fontColour
    ? details.fontColour
    : '#000000';
  const bgColour = 'bg' in details && details.bg && 'bgColour' in details && details.bgColour
    ? details.bgColour
    : undefined;
  const baseStyle: CSSProperties = {
    ...generatePartyCustomFieldStyle(party?.colour, selected),
    width: `${details.position.width}px`,
    height: `${details.position.height}px`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: customFieldMetas[details.type]?.predefinedText ? 'start' : 'center',
    justifyContent: 'start',
    color,
    background: bgColour
      ? bgColour
      : party
        ? colorToRgba(party.colour, 0.2)
        : 'rgba(255, 255, 255, 0.2)'
  };
  const calculateTopLeftDelta = (dir: Direction, delta: NumberSize): { top: number, left: number } => {
    return {
      top: dir.toLowerCase().indexOf('top') >= 0
        ? -1 * delta.height
        : 0,
      left: dir.toLowerCase().indexOf('left') >= 0
        ? -1 * delta.width
        : 0
    };
  };

  const onContextHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onContext?.(e.clientX, e.clientY, e.target as Element);
  };

  const inResizableContext = (resizable && (selected || (hover && !otherSelected)));
  return <div
    onMouseEnter={() => setHover(true)}
    onMouseLeave={() => setHover(false)}
  >
    {inResizableContext
      ? <Resizable
        size={size}
        onResizeStart={e => {
          e.stopPropagation();
          e.preventDefault();
          onSelect?.();
          setDragStartSize(size);
        }}
        onResize={(e, dir, ref, delta) => {
          if (!dragStartSize) return;
          const tlDelta = calculateTopLeftDelta(dir, delta);
          setSize({
            width: dragStartSize.width + delta.width,
            height: dragStartSize.height + delta.height,
            top: dragStartSize.top + tlDelta.top,
            left: dragStartSize.left + tlDelta.left
          });
          setAllowed(onResizing({
            ...delta,
            ...tlDelta
          }));
        }}
        onResizeStop={(e, dir, ref, delta) => {
          if (!onResize({
            ...delta,
            ...calculateTopLeftDelta(dir, delta)
          })) {
            setSize({
              top: 0,
              left: 0,
              width: details.position.width,
              height: details.position.height
            });
          }
          setDragStartSize(undefined);
          setAllowed(true);
        }}
        style={{
          ...(selected ? selectedPartyCustomFieldStyle : {}),
          top: `${size.top}px`,
          left: `${size.left}px`,
          ...(allowed ? {} : { cursor: 'not-allowed' })
        }}
        handleStyles={{
          top: resizeHandleTop,
          bottom: resizeHandleBottom,
          right: resizeHandleRight,
          left: resizeHandleLeft,
          topLeft: resizeHandleTopLeft,
          bottomLeft: resizeHandleBottomLeft,
          topRight: resizeHandleTopRight,
          bottomRight: resizeHandleBottomRight
        }}
        handleWrapperClass={allowed ? '' : 'not-allowed-and-children'}
        className={allowed ? '' : 'not-allowed-and-children'}
      >
        <div
          style={{
            ...baseStyle,
            width: '100%',
            height: '100%',
            position: 'absolute'
          }}
          className={clsJn(
            defaultFieldClassName,
            'prevent-select'
          )}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onSelect?.();
          }}
          onContextMenu={onContextHandler}
        >
          <RenderExistingCustomFieldInternal
            details={details}
            party={party}
            contentScale={contentScale}
            property={property}
            onEdit={selected ? onEdit : undefined}
            onAttrChange={selected ? onAttrChange : undefined}
          />
        </div>
      </Resizable>
      : <div
        id={details.id}
        style={{
          ...baseStyle,
          ...(selected ? selectedPartyCustomFieldStyle : {})
        }}
        className={clsJn(
          defaultFieldClassName,
          dragging && 'shadow',
          'prevent-select'
        )}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onSelect?.();
        }}
        onContextMenu={onContextHandler}
        title={resizable ? 'Click to edit and resize' : 'Click to edit'}
      >
        <RenderExistingCustomFieldInternal
          details={details}
          party={party}
          contentScale={contentScale}
          property={property}
          onEdit={selected ? onEdit : undefined}
          onAttrChange={selected ? onAttrChange : undefined}
        />
      </div>}
  </div>;
}

function RenderExistingCustomFieldInternal({
  details,
  party,
  contentScale,
  property,
  onEdit,
  onAttrChange
}: {
  details: ExistingCustomFieldDetails,
  party?: CustomFieldPartyInfo,
  contentScale: number,
  property: MaterialisedPropertyData,
  onEdit?: (text: string) => void,
  onAttrChange?: (name: string, raw: any) => void
}) {
  const style = getStyles(details, contentScale);
  switch (details.type) {
    case CustomFieldType.remoteCheck: {
      const checkSize = formatNumericAttribute('fontSize', details, value => `${contentScale * value}px`);
      return <RemoteCheckbox
        label={details.label}
        labelStyle={style}
        checkStyle={{
          height: checkSize,
          width: checkSize
        }}
        onEdit={onEdit}
        onAttrChange={onAttrChange}
        checked={details.on}
      />;
    }
    case CustomFieldType.remoteRadio: {
      const radioSize = formatNumericAttribute('fontSize', details, value => `${contentScale * value}px`);
      return <RemoteRadio
        label={details.label}
        labelStyle={style}
        radioStyle={{
          height: radioSize,
          width: radioSize
        }}
        onEdit={onEdit}
        onAttrChange={onAttrChange}
        checked={details.on}
      />;
    }
    default:
      return <>
        {customFieldMetas[details.type].showIconForExisting && <Icon {...customFieldMetas[details.type].icon} />}
        <FormattedFieldText details={details} party={party} property={property} onEdit={onEdit} style={style} />
      </>;
  }
}

const defaultFieldClassName = 'gap-2 overflow-hidden';
const defaultPartyCustomFieldStyle: React.CSSProperties = {
  // using outline: shorthand will make react complain,
  // because we're combining this base style with overrides of the expanded style props.
  outlineColor: 'lightgrey',
  outlineStyle: 'solid',
  outlineWidth: '1px'
};
const selectedOutlineThickness = 4;
const selectedOutlineOffset = 2;
const selectedPartyCustomFieldStyle: React.CSSProperties = {
  outlineColor: '#5050ef88',
  outlineStyle: 'solid',
  outlineWidth: `${selectedOutlineThickness}px`,
  outlineOffset: `${selectedOutlineOffset}px`
};
const resizeHandleSize = 12;
const resizeHandleHalf = resizeHandleSize / 2;
const resizeHandleOffset = (selectedOutlineThickness / 2) + selectedOutlineOffset;
const calc50 = `calc(50% - ${resizeHandleHalf}px)`;
const calc0 = `-${resizeHandleHalf+resizeHandleOffset}px`;
const baseResizeHandleStyle: React.CSSProperties = {
  border: '1px solid #000',
  background: '#fff',
  width: `${resizeHandleSize}px`,
  height: `${resizeHandleSize}px`
};
const resizeHandleLeft = { ...baseResizeHandleStyle, left: calc0, top: calc50 };
const resizeHandleTopLeft = { ...baseResizeHandleStyle, left: calc0, top: calc0 };
const resizeHandleTop = { ...baseResizeHandleStyle, top: calc0, left: calc50 };
const resizeHandleTopRight = { ...baseResizeHandleStyle, top: calc0, right: calc0 };
const resizeHandleRight = { ...baseResizeHandleStyle, right: calc0, top: calc50 };
const resizeHandleBottomRight = { ...baseResizeHandleStyle, right: calc0, bottom: calc0 };
const resizeHandleBottom = { ...baseResizeHandleStyle, bottom: calc0, left: calc50 };
const resizeHandleBottomLeft = { ...baseResizeHandleStyle, bottom: calc0, left: calc0 };

export function generatePartyCustomFieldStyle(partyColour?: string, selected?: boolean): React.CSSProperties {
  // todo: differentiate between new/existing.
  // existing should have the fun colours all around, but not the dragged thingos.
  return partyColour
    ? {
      ...defaultPartyCustomFieldStyle,
      ...{
        outlineColor: partyColour,
        outlineStyle: 'solid',
        outlineWidth: '1px',
        background: `${partyColour}`
      }
    }
    : defaultPartyCustomFieldStyle;
}

export function colorToRgba(color: string, a: number): string {
  if (color.startsWith('rgba')) return color;
  if (color.startsWith('rgb')) {
    return rgbToRgba(color, a);
  }

  return hexToRgba(color, a);
}

function hexToRgba(hex: string, a: number) {
  const { r, g, b } = parseHexToRgbComponents(hex) || { r: 255, g: 255, b: 255 };
  return `rgba(${r}, ${g}, ${b}, ${a})`;
}

function parseHexToRgbComponents(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : undefined;
}

function rgbToRgba(rgb: string, a: number): string {
  const match = /rgb\((\d+),(\d+),(\d+)\)/.exec(rgb);
  if (!match) return rgb;
  const [_, r, g, b] = match;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
}
