import {
  Annexure,
  ContentType,
  FileRef,
  FormCode,
  FormInstance,
  TransactionMetaData,
  UploadType
} from '@property-folders/contract';
import { FormUtil } from '@property-folders/common/util/form';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useLightweightTransaction, useTransactionField } from '../../hooks/useTransactionField';
import _ from 'lodash';
import { DataGeneration, DetermineReplacedRemovedLockedResult } from '@property-folders/common/util/dataExtractTypes';
import { LineageContext } from '../../hooks/useVariation';
import { PathType } from '@property-folders/contract/yjs-schema/model';

import {
  getHierarchyNode,
  getValueByPath,
  normalisePathToStr,
  normalisePathToStrArray
} from '@property-folders/common/util/pathHandling';
import { composeErrorPathClassName } from '@property-folders/common/util/formatting';
import { useSelector } from 'react-redux';
import { preservedAnnexureOrderHistoryList } from '@property-folders/common/util/form/annexure';
import { createAnnexureFromUploadedDocument, deleteAnnexure, undoReplacementAnnexure } from '@property-folders/common/util/annexure';
import { ensureFormStatesInstances, FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { FormContext } from '../../context/FormContext';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { FormUserInteractionContext } from '../../context/FormUserInteractionContext';
import '../../dragged-components/form/ProposedLotAnnexure.scss';
import { UploadedDocumentsSelector } from '../../dragged-components/UploadedDocumentsSelector';
import { InstanceHistory, MaterialisedPropertyData, PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { materialisePropertyData, materialisePropertyMetadata } from '@property-folders/common/yjs-schema/property';
import { applyMigrationsV2 } from '@property-folders/common/yjs-schema';
import { Predicate } from '@property-folders/common/predicate';

/**
 *
 * @param param0.parentPath Path to proposedLot object parent (as applicable)
 * @param param0.myPath Path to proposedLot object
 * @returns
 */
export function SectionReplacementAnnexureV2({ parentPath, myPath, uploadType, typeTitle, texts }: {
  parentPath?: PathType
  myPath?: PathType,
  typeTitle: string,
  uploadType: UploadType,
  texts: {
    selectLabel: string,
    dropOverlayLabel: string,
    fieldError: string
  }
}) {
  const { transactionRootKey, docName, ydoc, transactionMetaRootKey } = useContext(YjsDocContext);
  const { formId, formName: formCode } = useContext(FormContext);

  const fullPathBase = normalisePathToStr([...normalisePathToStrArray(parentPath??[]), ...normalisePathToStrArray(myPath??[])]);

  const formFamilyCode = FormTypes[formCode].formFamily;
  const familyCodePath = `family_${formFamilyCode}`;

  const {
    value: annexureRef,
    handleUpdate: updateAnnexureRef,
    handleRemove: removeAnnexureRef,
    fullPath,
    transactionRootKey: annexureRefKey
  } = useTransactionField<string>({
    parentPath: fullPathBase,
    myPath: familyCodePath
  });

  const { snapshotHistory } = useContext(LineageContext);
  const noDeleteVariation = anyPreviousSnapshotLocksSectionAnnexureMode({ snapshotHistory, testPath: fullPathBase });
  const formParentPath = FormUtil.getFormPath(formCode, formId) || '';
  const annexuresPath = `${formParentPath}.annexures`;
  const { updateDraft: updateAnnexures } = useYdocBinder<Annexure[]>({ path: annexuresPath, bindToMetaKey: true });
  const { updateDraft: updateRootData } = useYdocBinder<MaterialisedPropertyData>({ path: '' });

  const annexuresForm = useLightweightTransaction<Annexure[]>({ parentPath: formParentPath, myPath: 'annexures', bindToMetaKey: true })?.value??[];

  const { annexures, usingPrevious, orderList } = useMemo(()=>{
    return preservedAnnexureOrderHistoryList(annexuresForm, snapshotHistory);
  }, [annexuresForm, snapshotHistory]);

  const shouldShowFullValidationError = useContext(FormUserInteractionContext).userShouldSeeAllValidation;
  const fullValidationError = useSelector((state: any) => {
    const baseTree = state?.validation?.errorPaths?.[docName ?? '']?.[transactionRootKey ?? '']?.[formCode];
    const result = !!getHierarchyNode(normalisePathToStrArray(fullPathBase), baseTree);
    return !!result;
  });

  const flagValidationError = shouldShowFullValidationError && fullValidationError;

  const proposalAnnexureCheck = _.find(annexures, annex=>annex.data.id === annexureRef);
  const proposalAnnexure = proposalAnnexureCheck && typeof proposalAnnexureCheck === 'object' && proposalAnnexureCheck.state !== DataGeneration.Removed ? proposalAnnexureCheck : null;

  // if there is an annexure but not an uploaded file, fix it
  useEffect(() => {
    if (!ydoc) return;

    const data = materialisePropertyData(ydoc, transactionRootKey);
    const meta = materialisePropertyMetadata(ydoc, transactionMetaRootKey);
    const annexureRefPathSegs = normalisePathToStrArray([
      ...normalisePathToStrArray(fullPathBase??[]),
      familyCodePath
    ]);
    const annexureFileId = getValueByPath(annexureRefPathSegs, data, true);

    if (typeof annexureFileId !== 'string') {
      return;
    }
    //if (fullPathBase.startsWith('market')) debugger;
    let info = FormUtil.getFormState(formCode, formId, meta)?.annexures?.find(a => a.id === annexureFileId);
    if (!info && !snapshotHistory) {
      return;
    } else if (!info) {
      const allTimelines = snapshotHistory?.signingTimelines;
      const res = allTimelines?.flat()?.map(inst=>inst.annexures??[]).flat()?.find(a=>a.id===annexureFileId);
      if (!res) return;
      info = res;
    }

    const upload = typeof annexureFileId === 'string'
      ? FormUtil.getFormState(FormCode.UploadedDocument, annexureFileId, meta)
      : undefined;

    if (upload) {
      return;
    }

    const newInstance: FormInstance = {
      id: info.id,
      formCode: FormCode.UploadedDocument,
      created: Date.now(),
      modified: Date.now(),
      upload: {
        v: 2,
        name: 'name' in info && info.name
          ? info.name
          : typeTitle,
        files: [{
          id: info.id,
          contentType: 'contentType' in info && info.contentType
            ? info.contentType
            : ContentType.Pdf,
          order: 0,
          name: 'name' in info && info.name
            ? info.name
            : typeTitle
        }]
      }
    };

    applyMigrationsV2<TransactionMetaData>({
      doc: ydoc,
      docKey: PropertyRootKey.Meta.toString(),
      typeName: 'Property',
      migrations: [{
        name: 'add missing uploaded document',
        fn: state => {
          const instances = ensureFormStatesInstances(state, FormCode.UploadedDocument);
          if (instances.some(i => i.id === newInstance.id)) return;

          instances.push(newInstance);
        }
      }]
    });
  }, [ydoc, transactionMetaRootKey, transactionRootKey]);
  const selectedRefs = useMemo<FileRef[]>(() => {
    if (!proposalAnnexure?.data) return [];
    const data = proposalAnnexure.data;
    const { id } = data;
    return [{
      id,
      contentType: 'contentType' in data && data.contentType ? data.contentType : ContentType.Pdf
    }];
  }, [proposalAnnexure?.data?.id]);

  const handleSelectionChange = useCallback((refs: FileRef[]) => {
    const ref = refs.at(0);
    const historicalItem = determineLastHistoricalAnnexure({ snapshotHistory, orderList, refPath: fullPathBase });
    if (historicalItem && ref) {
      // This replaces across form versions, which operates differently to the surgeon.replace that
      // is used internally in createAnnexureFromUploadedDocument
      createAnnexureFromUploadedDocument({
        file: ref,
        name: typeTitle,
        uploadType: uploadType,
        updateAnnexures,
        replaceInVariation: historicalItem,
        managed: true,
        binding: {
          path: fullPathBase,
          root: annexureRefKey
        },
        uploadTypeUnique: true,
        orderList: orderList || []
      });
      updateAnnexureRef(ref.id, true);
    } else if (ref) {
      // add/change annexure
      createAnnexureFromUploadedDocument({
        file: ref,
        name: typeTitle,
        uploadType: uploadType,
        updateAnnexures,
        replace: proposalAnnexure?.id,
        managed: true,
        binding: {
          path: fullPathBase,
          root: annexureRefKey
        },
        uploadTypeUnique: true,
        orderList: orderList || []
      });
      updateAnnexureRef(ref.id, true);
    } else if (proposalAnnexure?.id) {
      // remove annexure
      const annexure = annexures.find(x => x.id === proposalAnnexure.id);

      if (!historicalItem && annexure) {
        deleteAnnexure(annexure.data, usingPrevious, annexures, updateAnnexures);
        removeAnnexureRef();
      } else if (historicalItem) {
        // This one already searches the data model for the ID and replaces it
        undoReplacementAnnexure({ replacementAnnexureToBeRemoved: proposalAnnexure?.data, updateAnnexures, updateData: updateRootData });
      }

    }
  }, [updateAnnexureRef, removeAnnexureRef, updateAnnexures, proposalAnnexure?.data?.id, annexures, snapshotHistory, orderList]);

  return <div className='flex-grow-1'>
    <UploadedDocumentsSelector
      selectedFileRefs={selectedRefs}
      onSelect={handleSelectionChange}
      multi={false}
      uploadTypeFilter={uploadType}
      label={texts.selectLabel}
      overlayLabel={texts.dropOverlayLabel}
      error={flagValidationError ? texts.fieldError : undefined}
      inputClassName={composeErrorPathClassName(fullPathBase, undefined)}
      allowClear={!noDeleteVariation || proposalAnnexure?.id !== proposalAnnexure?.data?.id}
    />
  </div>;
}

export function anyPreviousSnapshotLocksSectionAnnexureMode({ snapshotHistory, testPath }: { snapshotHistory: InstanceHistory | undefined; testPath: PathType; }) {
  if (!snapshotHistory) return false;
  const orderedInstanceSets = snapshotHistory?.signingTimelines;
  const latestInstanceSet = orderedInstanceSets?.[orderedInstanceSets.length - 1];
  if (!latestInstanceSet) return false; // maybe nothing is signed yet. Well anyway
  return !!latestInstanceSet.find(i => {
    const snapProperty = (snapshotHistory?.data?.[i?.signing?.session?.associatedFiles?.propertyDataSnapshot?.id ?? ''] as MaterialisedPropertyData | undefined);
    const testNode = getValueByPath(testPath, snapProperty ?? {}, true);
    if (testNode) return true;
  });
}

export function determineLastHistoricalAnnexure({ snapshotHistory, refPath, refPathIsDirectId }: { snapshotHistory: InstanceHistory | undefined; orderList: DetermineReplacedRemovedLockedResult<Annexure>[] | null; refPath: PathType; refPathIsDirectId?: boolean; }) {
  const orderedInstanceSets = snapshotHistory?.signingTimelines;
  const latestInstanceSet = orderedInstanceSets?.[orderedInstanceSets.length - 1];

  const annexureRefHistory = latestInstanceSet?.map(i => {
    const snapProperty = (snapshotHistory?.data?.[i?.signing?.session?.associatedFiles?.propertyDataSnapshot?.id ?? ''] as MaterialisedPropertyData | undefined);
    const baseObj = getValueByPath(refPath, snapProperty ?? {}, true);
    if (refPathIsDirectId) return baseObj;
    const formFam = FormTypes[i.formCode]?.formFamily;
    return baseObj?.['family_' + formFam];
  }).filter(Predicate.isTruthy);
  const lastId = annexureRefHistory?.[annexureRefHistory?.length - 1];
  // the last ID may not exist in the last instance, so let's go over the list again and get the annexure when it was created
  for (const inst of [...latestInstanceSet ?? []].reverse()) {
    const maybeAnnex = inst.annexures?.find(a => a.id === lastId && !a._removedMarker && !a._restoredMarker);
    if (maybeAnnex) return maybeAnnex;
  }
}

