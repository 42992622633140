import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import { coolingOffOpts } from '@property-folders/contract';
import React, { useState } from 'react';
import { CoolingOffModal, CoolingOffModalData } from './coolingOffModal';

export const CoolingOff = (props: TransactionConsumerProps & {
  purchaserMode?: boolean
}): JSX.Element => {
  const { purchaserMode } = props;
  const [changeModal, setChangeModal] = useState<CoolingOffModalData>({ visible: false });

  return (
    <>
      <div className="w-100">
        {!purchaserMode && <div className='scrollspy-target' data-focus-path="subsection-cooling-off">
          <div className='w-100 d-flex'>
            <div className='mt-2'>
              <WrField.CheckRadio
                label={''}
                radioType='checkbox'
                options={coolingOffOpts}
                name='purchaserRight'
                myPath='purchaserRight'
                parentPath={'coolingOff'}
                inline={false}
                afterChange={value => {
                  const newValue = value === 'no' ? 'immediate' : value === 'yes' ? 'afterCooling' : value === 'waived' ? 'coolingWaived' : undefined;
                  newValue && setChangeModal({ visible: true, changedField: 'coolingOff', newValue: newValue });
                }}
              />
            </div>
          </div>
        </div>}
      </div>

      <CoolingOffModal
        {...changeModal}
        onClose={()=>setChangeModal({ visible: false })}
      />
    </>
  );
};
