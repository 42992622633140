import { AgentSessionInfoResult, SigningParty, SigningPartySnapshot } from '@property-folders/contract';
import { Button } from 'react-bootstrap';
import { Predicate } from '@property-folders/common/predicate';
import { canonicalisers, getTimeString } from '@property-folders/common/util/formatting';
import { Icon } from '../../dragged-components/Icon';
import { useMemo } from 'react';
import { getEmailEventText } from '../../dragged-components/signing/PartySessionCard';

function someContactAvailable (party?: DistributionParty) {
  const proxyMode = Predicate.proxyNotSelf(party?.proxyAuthority);
  return !!(proxyMode ? (party?.proxyEmail || party?.proxyPhone) : (party?.snapshot?.phone || party?.snapshot?.email));
}

function delayActive (party: DistributionParty|null|undefined) {
  return party?.distributionState?.type === 'delayed' && someContactAvailable(party) && !party?.notificationBlock;
}

function markedManually(party: DistributionParty|null|undefined) {
  return party?.distributionState?.type === 'manual';
}

function awaitingConfirmation(party: DistributionParty|null|undefined) {
  return party?.distributionState?.type === 'manualWaiting';
}

function partyStatusLine(party: DistributionParty, sendingNow?: boolean) {
  if (party.notificationBlock) {
    return <div className='d-flex align-items-center fw-bold'>
      <Icon name='block' icoClass='me-1' />
      Suppressed
    </div>;
  }

  if (['sms', 'email', 'manual'].includes(party.distributionState?.type)) {
    return <div className='d-flex align-items-center fw-bold'>
      <Icon name='check_circle' icoClass='me-1' style={{ color: 'green' }} />
    Distributed
    </div>;
  }

  if (!someContactAvailable(party) && awaitingConfirmation(party)) {
    return <div className='d-flex align-items-center fw-bold'>
      <Icon name='schedule' icoClass='me-1' />
      Confirmation Required
    </div>;
  }

  if (!someContactAvailable(party)) {
    return <div className='d-flex align-items-center fw-bold'>
      <Icon name='warning' icoClass='me-1' style={{ color: 'orange' }} />
      Distribution Required
    </div>;
  }

  if (sendingNow && delayActive(party)) {
    return <div className='d-flex align-items-center fw-bold'>
      <Icon name='pending' icoClass='me-1' />
    Pending
    </div>;
  }

  if (delayActive(party)) {
    return <div className='d-flex align-items-center fw-bold'>
      <Icon name='schedule' icoClass='me-1' />
      Distribution Delayed
    </div>;
  }
}

function partyEmailDelivery(party: DistributionParty, sessionInfo: AgentSessionInfoResult | undefined) {
  if (party.distributionState?.type !== 'email') return;
  return getEmailEventText(
    party.distributionState.lastDistributionEmailEvent,
    getTimeString(party.distributionState.lastDistributionEmailEventTimestamp,sessionInfo?.timeZone, 'at'),
    'distrib'
  );
}

// todo: may need to rework to support cc recipients as well?
function DistributionPartyStatusPanel({ party, sessionInfo, sendingNow }: {
  party: DistributionParty,
  sendingNow?: boolean | SendingNowParty
  sessionInfo: AgentSessionInfoResult | undefined
}) {
  const status = useMemo(()=>partyStatusLine(party, (sendingNow && typeof sendingNow === 'object') ? sendingNow.id === party.id : (sendingNow??false)), [
    party.distributionState?.type,
    sendingNow
  ]);

  const emailStatus = partyEmailDelivery(party, sessionInfo);
  const dType = party.distributionState?.type;
  const proxyMode = Predicate.proxyNotSelf(party.proxyAuthority);
  const method = dType === 'email'
    ? 'Method: Email Link'
    : dType === 'sms'
      ? 'Method: SMS Link'
      : dType === 'manual'
        ? 'Method: Marked by agent'
        : null;
  const delayMethod = !delayActive(party)
    ? null
    : (proxyMode
      ? (party.proxyEmail
        ? 'Method: Email Link'
        : 'Method: SMS Link'
      )
      : party.snapshot?.email
        ? 'Method: Email Link'
        : 'Method: SMS Link'
    );

  return <div className='flex-grow-1 mb-2' style={{ width: '250px' }}>
    {status}
    {!someContactAvailable(party) && !markedManually(party) && !awaitingConfirmation(party) && <div>No email address provided</div>}
    {!someContactAvailable(party) && awaitingConfirmation(party) && <div>Awaiting manual distribution by Agent</div>}
    {method && <div>{method}</div>}
    {delayMethod && <div key='Delay method'>{delayMethod}</div>}
    {delayActive(party) && <div>Delayed until 11pm</div>}
    {emailStatus && <div>{emailStatus}</div>}
  </div>;
}

export type DistributionPartySnapshot = Pick<SigningPartySnapshot,
  | 'name'
  | 'email'
  | 'phone'
>;
export type DistributionParty = Pick<SigningParty,
  | 'id'
  | 'proxyAuthority'
  | 'proxyName'
  | 'proxyEmail'
  | 'proxyPhone'
  | 'distributionState'
  | 'notificationBlock'
> & {
  snapshot?: DistributionPartySnapshot
};

export interface SendingNowParty {
  id: string;
}

export function DistributionPartyRow({ party, sessionInfo, sendingNow, onMarkPartyManuallyDistributed, onResendToParty, onSendToParty, onPartyToDownload, downloadAvailable, onRedownload }: {
  party: DistributionParty,
  sessionInfo: AgentSessionInfoResult | undefined,
  sendingNow?: boolean | SendingNowParty;
  onMarkPartyManuallyDistributed: (party: DistributionParty) => void,
  onPartyToDownload: (party: DistributionParty) => void
  onRedownload: () => void
  onResendToParty: (party: DistributionParty) => void
  onSendToParty: (party: DistributionParty) => void,
  downloadAvailable: boolean
}) {
  const signingParty = party;
  const proxyMode = Predicate.proxyNotSelf(signingParty?.proxyAuthority);
  const signerName = proxyMode ? signingParty.proxyName : signingParty?.snapshot?.name;
  const onBehalfText = proxyMode ? `On behalf of ${signingParty?.snapshot?.name}` : '';
  const signerEmail = proxyMode ? signingParty.proxyEmail : signingParty?.snapshot?.email;
  const signerPhone = proxyMode ? signingParty.proxyPhone : signingParty?.snapshot?.phone;

  const displayName = [signerName, signerEmail, signerPhone].find(x => !!x) || '';

  return <div className='d-flex mt-3'>
    <div className='flex-shrink-1 flex-grow-1'>
      <div className='d-flex flex-wrap' >
        <div className='flex-grow-1 mb-2' style={{ width: '250px' }}>
          <div className='fw-bold'>{displayName}</div>
          {onBehalfText && <div className=''>{onBehalfText}</div>}
          {Boolean(signerName && signerEmail) && <div className='text-truncate'>{signerEmail}</div>}
          {Boolean(signerName && signerPhone) && <div>{canonicalisers.phone(signerPhone||'').display}</div>}
        </div>
        <DistributionPartyStatusPanel party={signingParty} sessionInfo={sessionInfo} sendingNow={sendingNow} />

      </div>
    </div>

    <div className='flex-shrink-0 flex-grow-0 mb-2 signing-party-button-stack' style={{ width: '110px' }}>
      {downloadAvailable && ((!someContactAvailable(party) && !markedManually(party) && !awaitingConfirmation(party))) && <Button variant='outline-secondary' onClick={()=>onPartyToDownload(party)}>Download</Button>}
      {downloadAvailable && ((!someContactAvailable(party) && markedManually(party))) && <Button variant='outline-secondary' onClick={()=>onRedownload()}>Re-download</Button>}
      {((!someContactAvailable(party) && awaitingConfirmation(party))) && <Button variant='outline-secondary' onClick={()=>onMarkPartyManuallyDistributed(party)}>Confirm</Button>}
      {(someContactAvailable(party) && delayActive(party)) && <Button variant='outline-secondary' onClick={()=>onSendToParty(party)}>Send</Button>}
      {(['email', 'sms'].includes(party.distributionState?.type)) && <Button variant='outline-secondary' onClick={()=>onResendToParty(party)}>Resend</Button>}
    </div>
  </div>;
}
