import React, { useMemo, useRef } from 'react';
import { useDrop, XYCoord } from 'react-dnd';
import type { SourceType, TargetType } from 'dnd-core';
import { clamp } from 'lodash';

export interface DroppablePositionedProvided {
  innerRef: (element: HTMLElement | null) => any;
  isOver: boolean;
  canDrop: boolean;
  getZoneRect: () => DOMRect | undefined
}

export interface DroppedLocation {
  x: number;
  y: number;
  xPct: number;
  yPct: number;
}

/**
 * extend react-dnd to emit item's dropped position relative to the drop container
 * @param accept
 * @param children
 * @param onDrop
 * @constructor
 */
export function DroppablePositioned<TDropItemType>({
  accept,
  children,
  onDrop,
  getDroppedItemDimensions,
  checkCanDrop
}: {
  accept: TargetType,
  onDrop: (type: SourceType, item: TDropItemType, location: DroppedLocation, zoneRect: DOMRect) => void,
  children(provided: DroppablePositionedProvided): React.ReactElement<HTMLElement>,
  getDroppedItemDimensions(item: TDropItemType): { width: number, height: number },
  checkCanDrop?: (box: { x: number, y: number, width: number, height: number }, zoneRect: DOMRect) => boolean
}) {
  const ref = useRef<Element>();
  const [{ isOver, canDrop }, dropRef] = useDrop<TDropItemType, unknown, { isOver: boolean, canDrop: boolean }>({
    accept,
    drop: (item, monitor) => {
      const type = monitor.getItemType();
      if (!type) return;
      if (!ref.current) return;
      const zoneRect = ref.current.getBoundingClientRect();
      const itemCoord = monitor.getSourceClientOffset() as XYCoord;
      const { width, height } = getDroppedItemDimensions(item);
      const x = clamp(itemCoord.x - zoneRect.left, 0, zoneRect.width - width);
      const y = clamp(itemCoord.y - zoneRect.top, 0, zoneRect.height - height);

      onDrop(type, item, {
        x, y, xPct: x / zoneRect.width, yPct: y / zoneRect.height
      }, zoneRect);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    canDrop: checkCanDrop ? (item, monitor) => {
      // console.log('canDrop?', monitor.getSourceClientOffset());
      if (!ref.current) return true;
      const zoneRect = ref.current.getBoundingClientRect();
      const itemCoord = monitor.getSourceClientOffset() as XYCoord;
      const { width, height } = getDroppedItemDimensions(item);
      const x = clamp(itemCoord.x - zoneRect.left, 0, zoneRect.width - width);
      const y = clamp(itemCoord.y - zoneRect.top, 0, zoneRect.height - height);
      return checkCanDrop({ x, y, width, height }, zoneRect);
    } : undefined
  });
  const provided = useMemo<DroppablePositionedProvided>(() => {
    return {
      innerRef: (elem: HTMLElement | null) => {
        ref.current = elem ? elem : undefined;
        dropRef(elem);
      },
      isOver,
      canDrop,
      getZoneRect: () => {
        return ref.current?.getBoundingClientRect();
      }
    };
  }, [isOver, canDrop]);

  return children(provided);
}
