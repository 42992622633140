import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import { Predicate } from '@property-folders/common/predicate';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import { FloatingLabel, Form } from 'react-bootstrap';
import { canonicalisers } from '@property-folders/common/util/formatting';
import { ContractPriceType, depositPayAtOpts, MaterialisedPropertyData } from '@property-folders/contract';
import { calculateFinalPrice2 } from '@property-folders/common/util/pdfgen/sections/contract-price';
import React, { useState } from 'react';
import { CoolingOffModal, CoolingOffModalData } from './coolingOffModal';

export const ContractPrice = (props: TransactionConsumerProps & {
  purchaserMode?: boolean
  templateMode?: boolean
}): JSX.Element => {
  const { purchaserMode, templateMode } = props;
  const { value: property } = useLightweightTransaction<MaterialisedPropertyData>({ myPath: '' });
  const { value: contractPrice, fullPath  } = useLightweightTransaction<ContractPriceType>(props);
  const { onlyPrice } = calculateFinalPrice2(property?.vendorGst, contractPrice);
  const [changeModal, setChangeModal] = useState<CoolingOffModalData>({ visible: false });

  return (

    <div className="w-100">

      {!templateMode && <div className='subsection scrollspy-target' data-focus-path="subsection-contract-price">
        <div className='fs-4'>{purchaserMode ? 'Offer amount' : 'Purchase Price'}<sup className='fs-5' style={{ color: 'red' }}> *</sup></div>
        <div className='d-flex w-100 flex-wrap'>
          <div className='flex-grow-1 mt-2' style={{ minWidth: '200px' }}>
            <WrField.Control
              name='purchasePrice'
              myPath='purchasePrice'
              parentPath={fullPath}
              label='Purchase Price'
            />
          </div>

          {Predicate.boolFalse(onlyPrice) && !purchaserMode && <>
            <div className='flex-grow-1 mt-2' style={{ minWidth: '200px' }}>
              <WrField.Control
                name='purchaseGst'
                myPath='purchaseGst'
                parentPath={fullPath}
                label='GST on purchase price'
                key='gstField'
              />
            </div>
            <div className='flex-grow-1 mt-2' style={{ minWidth: '200px' }}>
              <FloatingLabel label='Total'>
                <Form.Control
                  disabled={true}
                  placeholder='Total'
                  value={typeof contractPrice?.purchasePrice === 'number' ? canonicalisers.aud(contractPrice?.purchasePrice + (contractPrice?.purchaseGst || 0)).display : undefined}
                />
              </FloatingLabel>
            </div>
          </>}
        </div>
      </div>}

      <div className='subsection scrollspy-target' data-focus-path="subsection-deposit">
        <CoolingOffModal
          {...changeModal}
          onClose={()=>setChangeModal({ visible: false })}
        />
        <div className='fs-4'>Deposit</div>
        {!templateMode && <div className='flex-grow-1 mt-2'>
          <WrField.Control
            name='deposit'
            myPath='deposit'
            parentPath={fullPath}
            label='Deposit'
            key='depositField'
          />
        </div>}
        {!purchaserMode && <>
          <div className='w-100 d-flex'>
            <div className='mt-2'>
              <WrField.CheckRadio
                label='Deposit will be paid:'
                radioType='checkbox'
                options={depositPayAtOpts}
                name='depositPayAt'
                myPath='depositPayAt'
                parentPath={fullPath}
                inline={false}
                afterChange={value => {
                  const newValue = value === 'immediate' ? 'no' : value === 'afterCooling' ? 'yes' : value === 'coolingWaived' ? 'waived' : undefined;
                  newValue && setChangeModal({ visible: true, changedField: 'deposit', newValue: newValue });
                }}
              />
            </div>
          </div>
          {contractPrice?.depositPayAt === 'other' && <div className='w-100 d-flex'>
            <div className='flex-grow-1 mt-2'>
              <WrField.Control
                name='depositDateOther'
                type='date'
                useCanonical={true}
                label={'Deposit payment date if other'}
                parentPath={fullPath}
                myPath='depositDateOther'
                placeholder='Date of deposit'
              />
            </div>
          </div>}
        </>}
      </div>
    </div>
  );
};
