import QpdfWorker from '../web-workers/qpdfWorker?worker';
import { useMemo } from 'react';
import { uuidv4 } from 'lib0/random';
import { isUint8ArrayBuffer } from '../web-workers/communication';

const inflightRequests: {
  [key: string]: {
    resolve: (buffer: ArrayBuffer | Uint8Array) => void;
    reject: (err: any) => void;
    type: 'decrypt'
    purpose?: string;
  }
} = {};

export function useQpdfWorker() {
  const pdfWorker: Worker = useMemo(
    () => {
      const pf = new QpdfWorker();
      pf.addEventListener('message', ev => {

        const { requestId, blob, errCaught, errInput } = ev.data ?? {};
        if (!requestId) {
          console.warn('QPDF worker sent invalid message', ev.data);
          return;
        }

        if (!inflightRequests[requestId]) {
          //PDF worker responded to unknown request, probably cancelled request
          return;
        }

        const { resolve, reject } = inflightRequests[requestId];

        if (errCaught) {

          if (errCaught.eMsg === 'FS error') {
            console.warn('Known QPdf Error, passing back input:', errCaught);
            resolve(isUint8ArrayBuffer(errInput) ? errInput.buffer : errInput);
          } else {
            reject(new Error('QPdf Error: '+errCaught.eMsg));
          }
          return;
        }

        if (!blob) {
          reject(new Error('QPDF worker responded without blob'));
          return;
        }

        resolve(isUint8ArrayBuffer(blob) ? blob.buffer : blob);
      });

      return pf;
    },
    []
  );

  if (!window.Worker) {
    // supported by all browsers since ie10... I think we'll be okay
    // https://caniuse.com/webworkers
    throw new Error('Web workers unavailable');
  }

  return {
    decrypt: (opts: { pdf: (Uint8Array | ArrayBuffer), password?: string }) => {
      const requestId = uuidv4();
      const pdf = opts.pdf;
      const bufferArray = isUint8ArrayBuffer(pdf) ? pdf.buffer : pdf;

      return new Promise<ArrayBuffer>((resolve, reject) => {
        pdfWorker.postMessage(
          {
            type: 'decrypt',
            pdf: bufferArray,
            requestId,
            password: opts.password
          },
          [bufferArray]
        );

        inflightRequests[requestId] = {
          resolve,
          reject,
          type: 'decrypt'
        };
      });
    }
  };
}
